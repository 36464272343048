import KitchenConfig from "./products/kitchenaid";

// Use this form if there are no custom elements - empty div
import Null from "./products/null";

const ProductList = {
  1: {
    type: "3D",
    imageURL:
      "https://user-images.githubusercontent.com/35647384/126229856-debd699e-4dc0-4667-89df-4e4c7efafbe5.png",
    threekit: "74d35340-1ee7-4828-b313-ad10bfe09727",
    name: "Door Configurator",
    scene: "",
    initialConfig: {},
    showAR: true,
    showConfig: true,
    brand: "Menards",
    form: Null,
  },
};
export { ProductList };
